export type AgentType = {
  lat: number
  lng: number
  name: string
  desc: string
  email: string
  tel: string
  address?: string
  website?: string
  img: string
  linkedin?: string
  facebook?: string
  instagram?: string
  googlemybusiness?: string
  hideOnPretto?: boolean
}

export interface FormatedAgentType extends AgentType {
  key: string
}

export const AGENTS_INFORMATIONS = [
  {
    lat: 48.794601,
    lng: 2.39572,
    name: 'Jérôme Leruch',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Agent immobilier',
    email: 'jerome.leruch@bypretto.fr',
    tel: '+33663052021',
    address: '20 Rue Massias, 94400 Vitry Sur Seine',
    website: 'https://www.neocasa.fr/',
    img: 'v1729263409/Mandataires%20Pretto%20Galaxie/Jerome_LERUCH.jpg',
    linkedin: 'linkedin.com/in/jérôme-leruch-courtierpretimmobilier',
  },
  {
    lat: 48.817021,
    lng: 1.961503,
    name: 'Sylvie Allard',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 's.a.courtage@gmail.com',
    tel: '+33640960203',
    address: '59 Rue Philippe Soupault, 78370 Plaisir',
    img: 'v1729262723/Mandataires%20Pretto%20Galaxie/Sylvie_ALLARD.jpg',
    linkedin: 'https://www.linkedin.com/in/sylvie-allard-8862bb18a',
  },
  {
    lat: 49.058066,
    lng: 2.163822,
    name: 'Eric Eon',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'eric.eon@bypretto.fr',
    tel: '+33749906150',
    address: '10 rue gaston monmousseau, 95540 Méry-sur-Oise',
    img: 'v1730206957/Mandataires%20Pretto%20Galaxie/Eric_Eon.jpg',
    linkedin: 'https://www.linkedin.com/in/eric-eon/',
  },
  {
    lat: 49.132861,
    lng: 2.512255,
    name: 'Serge Pruvot',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'serge.pruvot@bypretto.fr',
    tel: '+33678627320',
    address: '13 Rue De La Gare, 60560 Orry La Ville',
    website: 'https://www.mahretassocies.com/',
    img: 'v1729265335/Mandataires%20Pretto%20Galaxie/Serge_Pruvot.jpg',
    linkedin: 'https://www.linkedin.com/in/serge-pruvot-6242bb141/',
    facebook: 'https://www.facebook.com/profile.php?id=100063639021202',
    googlemybusiness: 'https://maps.app.goo.gl/EBFKZUBLXYukuGco6?g_st=com.google.maps.preview.copy',
  },
  {
    lat: 48.815094,
    lng: 2.292673,
    name: 'Valérie Serdimet',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt hypothécaire; Courtier en assurance (assurance emprunteur, prévoyance, ...); Courtier en prêt professionnel',
    email: 'vserdimet@financementpros.com',
    tel: '+33769815439',
    address: '12 IMPASSE DES NEGRIERS, 92240 MALAKOFF',
    website: 'https://www.financementpros.com/',
    img: 'v1733137493/Mandataires%20Pretto%20Galaxie/valerie_serdimet.jpg',
    linkedin:
      'https://www.linkedin.com/in/valerie-serdimet-207a137b?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B%2BOtIgaEbRmiXdCckmMFMsQ%3D%3D',
  },
  {
    lat: 47.259438,
    lng: -2.268601,
    name: 'David Lesueur',
    desc: 'Courtier en prêt immobilier',
    email: 'dl@davidlesueur.fr',
    tel: '+33620820571',
    address: 'Cabinet Kerles, 2 rue de l’étoile du matin, Aprolis 1, 44600 SAINT NAZAIRE',
    website: 'https://www.davidlesueur.fr/',
    img: 'v1734599164/Mandataires%20Pretto%20Galaxie/David_Lesueur.jpg',
    linkedin: 'http://linkedin.com/in/david-lesueur-6a259a137',
    facebook: 'https://www.facebook.com/david.lesueur.54/?__n=K',
    googlemybusiness: 'https://g.co/kgs/efY4pwf',
  },
  {
    lat: 48.867923,
    lng: 2.285959,
    name: 'Frédéric Spinga',
    desc: 'Courtier en prêt immobilier',
    email: 'frederic.spinga@bypretto.fr',
    tel: '+33624718158',
    address: '64 avenue Raymond Pointcarré, 75016 PARIS',
    img: 'v1733742930/Mandataires%20Pretto%20Galaxie/Fr%C3%A9d%C3%A9ric_Spinga.jpg',
    linkedin: 'https://www.linkedin.com/in/fredericspinga/',
  },
  {
    lat: 45.758,
    lng: 4.835,
    name: 'Virginie Pavia',
    desc: 'Courtier en prêt immobilier',
    email: 'virginie.pavia@bypretto.fr',
    tel: '+33651494056',
    address: 'Lyon',
    img: 'v1733137494/Mandataires%20Pretto%20Galaxie/Virginie_Pavia.jpg',
    linkedin: 'https://www.linkedin.com/in/virginie-pavia-48263129/',
  },
  {
    lat: 43.708079,
    lng: 7.330391,
    name: 'Douglas Martin',
    desc: 'Courtier en prêt immobilier',
    email: 'dmartin@mortgageinfrance.com',
    tel: '+33664329432',
    address: '8 Avenue des Anglais, 06310 Beaulieu-sur-Mer',
    website: 'https://mortgageinfrance.com/',
    img: 'v1733137490/Mandataires%20Pretto%20Galaxie/Douglas_Martin.jpg',
    linkedin: 'https://www.linkedin.com/in/douglas-martin-9925a14/',
  },
  {
    lat: 43.577387,
    lng: 3.879096,
    name: 'Eric Molina',
    desc: 'Courtier en prêt immobilier',
    email: 'molina@plugimmo.com',
    tel: '+33757953109',
    address: '255 Rue du Puech Radier, 34970 LATTES',
    website: 'www.plugimmo.pro',
    img: 'v1731526927/Mandataires%20Pretto%20Galaxie/Eric_Molina.jpg',
    linkedin: 'https://www.linkedin.com/in/eric-molina-plugimmo/',
  },
  {
    lat: 43.600068,
    lng: 1.445568,
    name: 'Hélène Barraud-Ousset',
    desc: 'Courtier en prêt immobilier',
    email: 'helene.barraud@bypretto.fr',
    tel: '+33646448142',
    address: '9 Rue D’Alsace Lorraine, 31000 Toulouse',
    website: 'https://www.solucredit.com/',
    img: 'v1729607982/Mandataires%20Pretto%20Galaxie/H%C3%A9l%C3%A8ne_Barraud-Ousset.jpg',
    linkedin: 'https://www.linkedin.com/in/helenebarraudousset',
    googlemybusiness: 'https://maps.app.goo.gl/ejGdsRGwQag87rfR8?g_st=ic',
  },
  {
    lat: 48.896525,
    lng: 2.095407,
    name: 'Patrice Verrier',
    desc: 'Courtier en prêt immobilier',
    email: 'p.verrier@coeos-groupe.com',
    tel: '+33663304072',
    address: '30 Bis Rue Du Vieil Abreuvoir, 78100 St Germain En Laye',
    website: 'https://patriceverrier.com/',
    img: 'v1729607981/Mandataires%20Pretto%20Galaxie/Gerald_Maestu.png',
    linkedin: 'https://www.linkedin.com/in/patrice-verrier/',
    googlemybusiness: 'https://g.co/kgs/2Xdg2L6',
  },
  {
    lat: 47.667245,
    lng: -2.756922,
    name: 'Ségolène De Mierry',
    desc: 'Courtier en prêt immobilier',
    email: 'segolene.de-mierry@bypretto.fr',
    tel: '+33744418489',
    address: '45 Avenue Wilson, 56000 Vannes',
    website: 'https://segolene-demierry.fr',
    img: 'v1729265171/Mandataires%20Pretto%20Galaxie/S%C3%A9gol%C3%A8ne_De_Mierry.jpg',
    linkedin: 'www.linkedin.com/in/ségolène-de-mierry-3b9590157',
    facebook: 'www.facebook.com/segolene.demierry',
    instagram: 'https://www.instagram.com/segolene_courtier_vannes',
    googlemybusiness: 'https://g.co/kgs/4c5ZpWZ',
  },
  {
    lat: 46.594633,
    lng: 4.057848,
    name: 'Loïc Dufraigne',
    desc: 'Courtier en prêt immobilier',
    email: 'l.dufraigne@lecourtageducharolais.fr',
    tel: '+33679915544',
    address: '71130 Gueugnon',
    website: 'https://lecourtageducharolais.fr/',
    img: 'v1728985483/Mandataires%20Pretto%20Galaxie/Lo%C3%AFc_Dufraigne.png',
    linkedin: 'https://www.linkedin.com/in/loicdufraigne',
    facebook: 'https://www.facebook.com/lecourtageducharolais',
    instagram: 'https://www.instagram.com/lecourtageducharolais',
    googlemybusiness: 'https://g.co/kgs/3wo5cBj',
  },
  {
    lat: 45.182081,
    lng: 5.7243,
    name: 'Mikael Ducanovic',
    desc: 'Courtier en prêt immobilier',
    email: 'Mducanovic@moncourtier.immo',
    tel: '+33781866212',
    address: '38000 Grenoble',
    website: 'www.moncourtier.immo',
    img: 'v1728985481/Mandataires%20Pretto%20Galaxie/Mikael_Ducanovic.jpg',
  },
  {
    lat: 46.041538,
    lng: 6.245943,
    name: 'Michaël Pinhel',
    desc: 'Courtier en prêt immobilier',
    email: 'michael@immocourtage.com',
    tel: '+33757821314',
    address: '100 Chemin De L’Étang, 74570 Filliere',
    website: 'www.immocourtage.com',
    img: 'v1728985481/Mandataires%20Pretto%20Galaxie/logo_noir_-_Micha%C3%ABl_Pinhel.png',
    linkedin: 'https://www.linkedin.com/in/michaelpinhelmartins/',
    facebook: 'https://www.facebook.com/Michaelimmocourtage',
    instagram: 'https://www.instagram.com/michael_immo_courtage/',
    googlemybusiness: 'https://g.co/kgs/yjmQeK1',
  },
  {
    lat: 43.65332,
    lng: 0.59063,
    name: 'Anthony Walter',
    desc: 'Courtier en prêt immobilier',
    email: 'contact@aw-conseil.com',
    tel: '+33651438464',
    address: '12 Rue Du 8 Mai, 32000 Auch',
    img: 'v1728985481/Mandataires%20Pretto%20Galaxie/Logo_2k24_-_Anthony_Walter.png',
    googlemybusiness: 'https://g.page/r/CSNLLELrk0cTEAE/review',
  },
  {
    lat: 48.799943,
    lng: 2.491801,
    name: 'Antoine Loriot',
    desc: 'Courtier en prêt immobilier',
    email: 'antoine.loriot@bypretto.fr',
    tel: '+33625961710',
    address: '94100 Saint Maur Des Fossés',
    img: 'v1728985481/Mandataires%20Pretto%20Galaxie/1676613466059_-_Antoine_Loriot.jpg',
    linkedin: 'linkedin.com/in/antoine-loriot-213096237',
    googlemybusiness: 'https://g.page/r/CeTMx_KWB4EsEAE/review',
  },
  {
    lat: 43.65729,
    lng: 1.4788,
    name: 'Alice Bonnal',
    desc: 'Courtier en rachat de crédits',
    email: 'alice.bonnal@bypretto.fr',
    tel: '+33658204709',
    address: '17 AVENUE DES HORTENSIAS, 31240 L’union',
    img: 'v1733137489/Mandataires%20Pretto%20Galaxie/Alice_Bonnal.jpg',
  },
  {
    lat: 43.342221,
    lng: 3.221653,
    name: 'Sandrine Duvoir',
    desc: 'Courtier en rachat de crédits',
    email: 'sandrine.duvoir@bypretto.fr',
    tel: '+33782784449',
    address: '5 boulevard de Liberté, 34500 Béziers',
    img: 'v1730206960/Mandataires%20Pretto%20Galaxie/Sandrine_Duvoir.jpg',
    linkedin: 'www.linkedin.com/in/sandrine-duvoir',
    instagram: 'https://www.instagram.com/sandrineduvoir_prettogalaxie/profilecard/?igsh=MW1kY2JsOG9xaWZm',
  },
  {
    lat: 47.2674,
    lng: -2.222486,
    name: 'Élodie Vallois',
    desc: 'Courtier en rachat de crédits',
    email: 'elodie.vallois@estuaire-finances-go.fr',
    tel: '+33659569719',
    address: '111 Rue De Pornichet, 44600 Saint - Nazaire',
    img: 'v1729262912/Mandataires%20Pretto%20Galaxie/Elodie_Vallois.jpg',
    linkedin: 'www.linkedin.com/in/elodievalloisestuairefinancesgrandouest',
    facebook: 'https://www.facebook.com/profile.php?id=61566070952015',
  },
  {
    lat: 45.768363,
    lng: 4.958356,
    name: 'Dorothée Desprez',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits',
    email: 'dorothee.desprez@bypretto.fr',
    tel: '+33680045320',
    address: '69150 Décines Charpieu',
    img: 'v1731579074/Mandataires%20Pretto%20Galaxie/Doroth%C3%A9e_Desprez.jpg',
    linkedin: 'https://www.linkedin.com/in/doroth%C3%A9e-desprez-4b97b9113/?originalSubdomain=fr',
    googlemybusiness:
      'https://www.google.com/search?sca_esv=d10577e322d75bca&rlz=1C1GCEA_enFR984FR984&q=Desprez%20Doroth%C3%A9e&stick=H4sIAAAAAAAAAONgU1I1qEg0Tza0NDFISUtJSbVMSTS1MqgwNE02SzS1SEsyTTFKTLNIXMQq6JJaXFCUWqXgkl-UX5JxeGUqAEaNzkQ9AAAA&mat=Cf6Z2lh6u9QK&ved=2ahUKEwiYz9eEvNuJAxXgTaQEHdNNM34QrMcEegQIFhAD',
  },
  {
    lat: 47.165548,
    lng: 5.565573,
    name: 'Christophe Courgey',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits',
    email: 'christophe.courgey@bypretto.fr',
    tel: '+33634059590',
    address: '2 Rue Du Stade, 39700 Amange',
    img: 'v1731528167/Mandataires%20Pretto%20Galaxie/Christophe_COURGEY_1.png',
    linkedin: 'www.linkedin.com/in/christophe-courgey-besançon',
    facebook: 'https://www.facebook.com/tom.select.967',
  },
  {
    lat: 48.634289,
    lng: -2.07277,
    name: 'Carole Jean',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits',
    email: 'c.jean@coda-courtage.fr',
    tel: '+33785502080',
    address: '30 rue de Dinard, 35800 Saint-Lunaire',
    website: 'https://coda-courtage.fr/',
    img: 'v1730206958/Mandataires%20Pretto%20Galaxie/Carole_Jean.jpg',
    linkedin: 'https://www.linkedin.com/company/coda-courtage/?viewAsMember=true',
    facebook: 'https://www.facebook.com/codacourtage',
    googlemybusiness: 'https://g.co/kgs/W6HRTeY',
  },
  {
    lat: 48.859,
    lng: 2.347,
    name: 'Nathalie Borg',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits',
    email: 'nathalie.borg@financactes.fr',
    tel: '+33606880066',
    address: '75000 Paris',
    website: 'https://www.financactes.com/',
    img: 'v1729263365/Mandataires%20Pretto%20Galaxie/Nathalie_JAOUEN_BORG.jpg',
    linkedin: 'https://www.linkedin.com/in/borg-courtier/',
    facebook: 'https://www.facebook.com/courtier.fr',
    googlemybusiness: 'https://g.page/r/CUe-9CoFPqAfEBM/',
  },
  {
    lat: 45.661429,
    lng: 0.180973,
    name: 'Samuel Chasles',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits',
    email: 'samuel.chasles@bypretto.fr',
    tel: '+33687943602',
    address: "9 Rue Parmentier,16340, L'Isle D'Espagnac",
    website: 'https://sc-courtage.webflow.io/',
    img: 'v1729263047/Mandataires%20Pretto%20Galaxie/Samuel_Chasles.jpg',
    linkedin: 'https://www.linkedin.com/in/samuel-chasles-59181513a/',
    googlemybusiness: 'https://g.co/kgs/Uj4SPTJ',
  },
  {
    lat: 43.685778,
    lng: 3.587794,
    name: 'Samuel Garrigues',
    desc: 'Courtier en prêt immobilier; Courtier en prêt professionnel',
    email: 'samuel@polaris-courtage.fr',
    tel: '+33686441043',
    address: '2068 chemin de Bourguet Naou, 34150 Aniane',
    website: 'https://polaris-courtage.fr',
    img: 'v1733137491/Mandataires%20Pretto%20Galaxie/Samuel_Garrigues.jpg',
  },
  {
    lat: 47.222453,
    lng: -1.731996,
    name: 'Adeline Polomack',
    desc: 'Courtier en prêt immobilier; Courtier en prêt professionnel',
    email: 'adeline.polomack@bypretto.fr',
    tel: '+33666698083',
    address: 'Rue Jacques Demy, 44220 COUERON',
    img: 'v1731686268/Mandataires%20Pretto%20Galaxie/Adeline_Polomack.jpg',
    instagram: 'https://www.instagram.com/adeline_polomack/?igsh=MTcyajk2YjBpbHdrdw%3D%3D&utm_source=qr#',
    googlemybusiness: 'https://g.page/r/CemzMgV31oK9EBM/review',
  },
  {
    lat: 43.342221,
    lng: 3.221653,
    name: 'Morgan Guinchard',
    desc: 'Courtier en prêt immobilier; Courtier en prêt professionnel',
    email: 'morgan.guinchard@bypretto.fr',
    tel: '+33616420017',
    address: '5 boulevard de la liberté, 34500 Béziers',
    img: 'v1731526931/Mandataires%20Pretto%20Galaxie/Morgan_Guinchard.jpg',
    linkedin: 'https://www.linkedin.com/in/morgan-guinchard/',
    instagram: 'https://www.instagram.com/morganguinchard_courtier/',
  },
  {
    lat: 43.415689,
    lng: -1.626273,
    name: 'Julie Desmas',
    desc: 'Courtier en prêt immobilier; Courtier en prêt professionnel',
    email: 'julie.desmas@bypretto.fr',
    tel: '+33669067950',
    address: '395 ROUTE DES PLAGES, 64500 SAINT JEAN DE LUZ',
    website: 'https://www.julie-desmas.fr/',
    img: 'v1731526930/Mandataires%20Pretto%20Galaxie/Julie_Desmas.jpg',
    linkedin: 'https://www.linkedin.com/in/julie-desmas-007136102/',
    instagram: 'https://www.instagram.com/julie_desmas_courtier/',
    googlemybusiness: 'https://g.page/r/CY5aCPIb9KXxEBM/review',
  },
  {
    lat: 47.250157,
    lng: -2.138381,
    name: 'Alexandre Lerouge',
    desc: 'Courtier en prêt immobilier; Courtier en prêt professionnel',
    email: 'alexandre.lerouge@bypretto.fr',
    tel: '+33685942734',
    address: '8 bis Rue de la lande mouron, 44250 Saint Brevin',
    img: 'v1731526926/Mandataires%20Pretto%20Galaxie/Alexandre_Lerouge.jpg',
  },
  {
    lat: 43.342221,
    lng: 3.221653,
    name: 'Pierre-Cédric Duvoir',
    desc: 'Courtier en prêt immobilier; Courtier en prêt professionnel',
    email: 'pierre-cedric.duvoir@bypretto.fr',
    tel: '+33672044543',
    address: '5 Boulevard de la liberté, 34500 BEZIERS',
    img: 'v1730206960/Mandataires%20Pretto%20Galaxie/Pierre-C%C3%A9dric_Duvoir.png',
  },
  {
    lat: 46.307919,
    lng: 6.308673,
    name: 'Hervé Delaveau',
    desc: 'Courtier en prêt immobilier; Conseiller en Investissements Financiers',
    email: 'herve.delaveau.conseil@gmail.com',
    tel: '+33781097095',
    address: '13 Avenue De Thonon, 74140 Douvaine',
    website: 'https://www.hervedelaveauconseil.fr/',
    img: 'v1728985484/Mandataires%20Pretto%20Galaxie/Herv%C3%A9_Delaveau.jpg',
    linkedin:
      'https://www.linkedin.com/in/herve-delaveau-35141386?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3Bmu3R5xYvTIya5SQBcWuOAg%3D%3D',
    facebook: 'https://www.facebook.com/share/u9UYJT24aqMtQkLp/?mibextid=LQQJ4d',
    instagram: 'https://www.instagram.com/hdelaveau?igsh=Mmppa2Y3dmx0eHZw',
    googlemybusiness: 'https://g.co/kgs/ZnWjGFq',
  },
  {
    lat: 49.43104,
    lng: 1.161856,
    name: 'Denis Hue',
    desc: 'Courtier en prêt immobilier; Conseiller en Investissements Financiers',
    email: 'huedenis01@gmail.com',
    tel: '+33608097949',
    address: '1209 Route De Lyons, 76160 Saint Léger Du Bourg Denis',
    website: 'www.frogsolutions.fr',
    img: 'v1728985481/Mandataires%20Pretto%20Galaxie/Denis_Hue.jpg',
    googlemybusiness: 'https://g.page/r/CQVgmEbu8yoqEBI/review',
  },
  {
    lat: 44.096706,
    lng: 5.031166,
    name: 'Nathalie Coeur',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'n.coeur@lesfeesdupret.fr',
    tel: '+33664178440',
    address: '84810 AUBIGNAN',
    img: 'v1736526814/Mandataires%20Pretto%20Galaxie/Nathalie_COEUR.png',
  },
  {
    lat: 47.293807,
    lng: -1.496754,
    name: 'Mehdi Noblet',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'mehdi.noblet@bypretto.fr',
    tel: '+33762905821',
    address: '57 rue Jules Verne, 44470 Carquefou',
    img: 'v1734599163/Mandataires%20Pretto%20Galaxie/Mehdi_Noblet.jpg',
  },
  {
    lat: 47.275247,
    lng: -2.242494,
    name: 'Sophie Allenou',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'sophie@bestimmopret.com',
    tel: '+33616744259',
    address: '44600 St Nazaire',
    website: 'https://bestimmopret.com/',
    img: 'v1733742931/Mandataires%20Pretto%20Galaxie/Sophie_Allenou.jpg',
    linkedin: 'https://www.linkedin.com/in/sophie-allenou-665954b6',
    facebook: 'https://www.facebook.com/sophie.allenou',
    googlemybusiness: 'https://g.co/kgs/3cL7A43',
  },
  {
    lat: 43.645158,
    lng: 6.944642,
    name: 'Fiona Carcone',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'fiona.carcone@bypretto.fr',
    tel: '+33781213634',
    address: '1 boulevard Marcel Pagnol, 06130 Grasse',
    img: 'v1733742930/Mandataires%20Pretto%20Galaxie/Fiona_Carcone.jpg',
    instagram: 'https://www.instagram.com/moncourtierprefere/',
  },
  {
    lat: 43.47144,
    lng: -1.555081,
    name: 'Pierrick Ballanger',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'pierrick.ballanger@bypretto.fr',
    tel: '+33650321776',
    address: '64200 Biarritz',
    img: 'v1733137493/Mandataires%20Pretto%20Galaxie/Pierrick_Ballanger.jpg',
    linkedin: 'https://www.linkedin.com/in/pierrick-ballanger-b3500524b/',
  },
  {
    lat: 48.936956,
    lng: 2.523857,
    name: 'Muriel Roué',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'muriel.roue@bypretto.fr',
    tel: '+33680486768',
    address: '32 RUS GASTON LEVY, 93270 SEVRAN',
    img: 'v1733137490/Mandataires%20Pretto%20Galaxie/Muriel_Rou%C3%A9.png',
    linkedin: 'https://www.linkedin.com/in/muriel-rou%C3%A9-452a1916a/',
    facebook: 'https://www.facebook.com/profile.php?id=100073342407924',
  },
  {
    lat: 48.86856,
    lng: 2.330722,
    name: 'Isabelle Vergnaud',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'isabelle.vergnaud@bypretto.fr',
    tel: '+33648248222',
    address: '4 rue de la Paix, 75002 PARIS',
    website: 'https://www.isabellevergnaud.fr/',
    img: 'v1733137490/Mandataires%20Pretto%20Galaxie/Isabelle_Vergnaud.png',
  },
  {
    lat: 43.922746,
    lng: 2.14678,
    name: 'Clovis Jossien',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'cj.financement@gmail.com',
    tel: '+33637436015',
    address: '1 Boulevard Carnot, 81000 Albi',
    img: 'v1733137489/Mandataires%20Pretto%20Galaxie/clovis_jossien.jpg',
    facebook: 'https://www.facebook.com/profile.php?id=61568406328433',
    instagram: 'https://www.instagram.com/cj_financement/',
  },
  {
    lat: 46.379919,
    lng: 4.804992,
    name: 'Florence Badet',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'florence.badet@lescourtiersassocies.fr',
    tel: '+33760880308',
    address: '150 Rue De La Bayarde, 71870 Laize',
    website: 'https://lescourtiersassocies.fr',
    img: 'v1729607993/Mandataires%20Pretto%20Galaxie/Florence_Badet.jpg',
  },
  {
    lat: 48.110899,
    lng: -1.68365,
    name: 'Jean-Philippe Grimaud',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'jpgrimaud@jean-finance.fr',
    tel: '+33660146188',
    address: '35000 Rennes',
    img: 'v1729607992/Mandataires%20Pretto%20Galaxie/Jean-Philippe_Grimaud.pdf',
    linkedin: 'linkedin.com/in/jeanphilippe-grimaud',
  },
  {
    lat: 47.240658,
    lng: 5.217502,
    name: 'Fabrice Buatois',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'fabrice.buatois@fb-courtage.fr',
    tel: '+33642760907',
    address: '1 Rue De Dijon, 21110 Genlis',
    website: 'https://www.fb-courtage.fr/',
    img: 'v1729607984/Mandataires%20Pretto%20Galaxie/Fabrice_Buatois.jpg',
    linkedin: 'https://www.linkedin.com/in/fabricebuatois/',
    googlemybusiness: 'https://g.co/kgs/E7oUVdF',
  },
  {
    lat: 48.864072,
    lng: 2.351886,
    name: 'Monique Hayoun',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'mhayoun.conseil@free.fr',
    tel: '+33614814989',
    address: '10 Rue Du Bourg L’Abbé, 75003 Paris',
    website: 'https://www.ma-courtiere.fr/',
    img: 'v1729607982/Mandataires%20Pretto%20Galaxie/Monique_Hayoun.jpg',
    linkedin: 'http://linkedin.com/in/monique-hayoun-conseils-financiers-courtière-en-prêt-immobilier-314b989',
    googlemybusiness: 'https://g.co/kgs/EgJS1iK',
  },
  {
    lat: 46.34226,
    lng: 2.605232,
    name: 'Sébastien Lafort',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'sebastien.lafort@bypretto.fr',
    tel: '+33621784393',
    address: '03100 Montlucon',
    website: 'https://www.slprofinances.fr',
    img: 'v1729265338/Mandataires%20Pretto%20Galaxie/S%C3%A9bastien_Lafort.jpg',
    linkedin:
      'https://www.linkedin.com/in/s%C3%A9bastien-lafort-3a9b2b29?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BQmLqnMlgQsS6UDB6oWu7jQ%3D%3D',
    facebook: 'https://www.facebook.com/profile.php?id=100057130584752',
    googlemybusiness: 'https://g.page/r/CdRHtcRi0aWvEBM/review',
  },
  {
    lat: 47.324817,
    lng: -2.426702,
    name: 'Nicolas Genetay',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'nicolas.genetay@bypretto.fr',
    tel: '+33631479666',
    address: '6 Impasse Du Corbon, 44350 Guérande',
    img: 'v1729263975/Mandataires%20Pretto%20Galaxie/Nicolas_Genetay.heic',
    linkedin: 'https://www.linkedin.com/in/nicolas-genetay-3a56b1151/',
  },
  {
    lat: 47.269634,
    lng: -1.554971,
    name: 'Cédric Nedelec',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'cedric.nedelec@bypretto.fr',
    tel: '+33677849380',
    address: '50 Rue Des Grives, 44240 La Chapelle Sur Erdre',
    website: 'https://www.cedric-nedelec.fr/',
    img: 'v1729263940/Mandataires%20Pretto%20Galaxie/C%C3%A9dric_Nedelec.jpg',
    linkedin: 'www.linkedin.com/in/cedric-nedelec',
    googlemybusiness: 'https://g.page/r/CS3PfNiprtpjEBM/review',
  },
  {
    lat: 47.353241,
    lng: 5.050701,
    name: 'Alexandre Larue',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'alexandre.larue@bypretto.fr',
    tel: '+33630887336',
    address: '11 Rue Jean Giono, 21000 Dijon',
    website: 'https://larueducourtage.fr/',
    img: 'v1729263233/Mandataires%20Pretto%20Galaxie/logo_Alexandre_Larue_Larue_du_courtage.png',
    linkedin: 'https://www.linkedin.com/in/alexandre-larue-32642395/',
  },
  {
    lat: 43.643422,
    lng: 3.842708,
    name: 'Bastien Andro',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'bastien.andro@bypretto.fr',
    tel: '+33680934043',
    address: '1006 Rue De La Croix Verte, 34090 Montpellier',
    img: 'v1729263173/Mandataires%20Pretto%20Galaxie/Bastien_Andro.jpg',
  },
  {
    lat: 46.30969,
    lng: 4.836502,
    name: 'Thibault Chevigny',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'thibault.chevigny@lescourtiersassocies.fr',
    tel: '+33651556188',
    address: '79 Rue Du 28 Juin 1944, 71000 Mâcon',
    website: 'https://www.thibaultchevignycourtier.fr',
    img: 'v1729263143/Mandataires%20Pretto%20Galaxie/Thibault_Chevigny.png',
    linkedin: 'https://www.linkedin.com/in/thibault-chevigny-8a080bb4/',
    facebook: 'https://www.facebook.com/CourtierMacon/?locale=fr_FR',
    googlemybusiness: 'https://g.co/kgs/QHthZMw',
  },
  {
    lat: 43.684588,
    lng: 1.438025,
    name: 'Jérémy Dallinges',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'jeremy.dallinges@bypretto.fr',
    tel: '+33609163435',
    address: "2 Rue Des Jardins De L'Hers, 31140 Fonbeauzard",
    img: 'v1729262878/Mandataires%20Pretto%20Galaxie/Jeremy_Dallinges.jpg',
  },
  {
    lat: 43.575585,
    lng: 3.822276,
    name: 'Sébastien Pilon',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'sebastien.pilon@bypretto.fr',
    tel: '+33613306482',
    address: '26 Rue Du Petit Parc, 34430 Saint Jean De Vedas',
    website: 'https://sebastien-pilon-courtier-finance-assurance.fr/',
    img: 'v1728985483/Mandataires%20Pretto%20Galaxie/S%C3%A9bastien_Pilon.jpg',
    linkedin: 'https://www.linkedin.com/in/s%C3%A9bastien-pilon/',
    googlemybusiness: 'https://g.co/kgs/MKtk5dG',
  },
  {
    lat: 49.051199,
    lng: -1.601436,
    name: 'Pierre Deglaire',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel',
    email: 'pierre.deglaire@bypretto.fr',
    tel: '+33663901301',
    address: '60 Rue Des Amiraux Mecquet, 50230 Agon Coutainville',
    img: 'v1729607994/Mandataires%20Pretto%20Galaxie/Pierre_Deglaire.jpg',
    linkedin: 'https://www.linkedin.com/in/pierre-deglaire-a26232131/',
  },
  {
    lat: 43.342221,
    lng: 3.221653,
    name: 'Nadège Ricard',
    desc: 'Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'nadege.ricard@bypretto.fr',
    tel: '+33676589135',
    address: '5 boulevard de la liberté, 34500 BEZIERS',
    img: 'v1731526932/Mandataires%20Pretto%20Galaxie/Nad%C3%A8ge_Ricard.jpg',
    linkedin: 'https://www.linkedin.com/in/nad%C3%A8ge-grassi-82a95361/?originalSubdomain=fr',
    instagram: 'https://www.instagram.com/oleen_beziers/',
    googlemybusiness:
      'https://www.google.com/maps/place/Courtiers+en+pr%C3%AAts+immobiliers+Beziers+by+Pretto/@43.3422554,3.2216977,17z/data=!3m1!4b1!4m6!3m5!1s0x12b10f14a10bac0f:0x6b8a64fd2021b35c!8m2!3d43.3422554!4d3.2216977!16s%2Fg%2F11vwy3yym8?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D',
  },
  {
    lat: 50.634265,
    lng: 3.056348,
    name: 'Julien Lecomte',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'juliennegoconseilcourtage@gmail.com',
    tel: '+33646712284',
    address: '115 rue Nationale, 59800 Lille',
    website: 'https://negoconseil.fr/',
    img: 'v1736526814/Mandataires%20Pretto%20Galaxie/Julien_Lecomte.png',
    linkedin: 'https://www.linkedin.com/in/julien-lecomte-369464271/',
    facebook: 'https://www.facebook.com/NEGOCONSEIL',
    instagram: 'https://www.instagram.com/negoconseil/',
    googlemybusiness: 'https://g.co/kgs/qPHNnDC',
  },
  {
    lat: 47.239367,
    lng: -1.555335,
    name: 'Antonhy Vastel' + 33,
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'contact@ecourtage.fr',
    tel: '+33252565758',
    address: '44000 Nantes',
    website: 'https://ecourtage.fr/',
    img: 'v1734599165/Mandataires%20Pretto%20Galaxie/Morgane_Riou.png',
    linkedin: 'https://www.linkedin.com/company/emprunte-courtage',
    facebook: 'https://www.facebook.com/profile.php?id=100087155824464',
    instagram: 'https://www.instagram.com/emprunte.courtage/',
    googlemybusiness: 'https://g.co/kgs/qcLc1zB',
  },
  {
    lat: 45.00296,
    lng: 4.85509,
    name: 'Florian Reynaud',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'florian.reynaud@bypretto.fr',
    tel: '+33645862078',
    address: '5 allée Jean Matisse, 26600 La Roche de Glun',
    website: 'https://florianreynaud.fr/',
    img: 'v1734599163/Mandataires%20Pretto%20Galaxie/Florian_Reynaud.jpg',
    googlemybusiness:
      'https://www.google.com/search?client=firefox-b-d&sa=X&sca_esv=304c4ee5713996df&tbm=lcl&sxsrf=ADLYWIKkgSH3KO9hg-a2GfuumUSYHKqmcQ:1734078896532&q=Florian+Reynaud+-+Courtier+en+cr%C3%A9dits+immobiliers+Avis&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2MTMyMDA3szA1tLS0MDKwNLE02sDI-IrR3C0nvygzMU8hKLUyL7E0RUFXwTm_tKgkM7VIITVPIbno8MqUzJJihczc3PykzBygcLGCY1lm8SJWcnUCAAvmIEiOAAAA&rldimm=4620076851998209492&hl=fr-FR&ved=2ahUKEwjbro-lq6SKAxWLVkEAHerKCiAQ9fQKegQIQxAF&biw=1642&bih=926&dpr=2#lkt=LocalPoiReviews',
  },
  {
    lat: 50.587214,
    lng: 3.111117,
    name: 'Alexis Trehout',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'a.trehout@assurances-et-financements.fr',
    tel: '+33766736726',
    address: '59810 Lesquin',
    website: 'www.assurances-et-financements.fr',
    img: 'v1734599162/Mandataires%20Pretto%20Galaxie/Alexis_Trehout.jpg',
    linkedin: 'linkedin.com/in/alexis-trehout-b4513b195',
    facebook: 'https://www.facebook.com/profile.php?id=100064334281829',
    googlemybusiness: 'https://g.co/kgs/6E5xmg7',
  },
  {
    lat: 45.185205,
    lng: 5.716778,
    name: 'Guillaume Mauduit',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'guillaume@moncourtierperso.fr',
    tel: '+33667181866',
    address: '15 Avenue de Vizille, 38000 Grenoble',
    website: 'https://moncourtierperso.fr/',
    img: 'v1733742930/Mandataires%20Pretto%20Galaxie/Guillaume_Mauduit.jpg',
    linkedin: 'https://www.linkedin.com/in/guillaume-mauduit-07580b121/',
    googlemybusiness: 'https://g.co/kgs/zn8ikus',
  },
  {
    lat: 48.788617,
    lng: 2.388681,
    name: 'Olivier Coste',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'olivier.coste@bypretto.fr',
    tel: '+33679631726',
    address: '2 Av. Youri Gagarine, 94400 Vitry-sur-Seine',
    img: 'v1731526933/Mandataires%20Pretto%20Galaxie/Olivier_Coste.jpg',
    linkedin: 'https://www.linkedin.com/in/olivier-coste-a1117376/',
  },
  {
    lat: 48.908795,
    lng: 2.286047,
    name: 'Yacine Redissi',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'yacine.redissi@bypretto.fr',
    tel: '+33636223622',
    address: '15 rue Bapst, 92600 Asnières sur Seine',
    website: 'www.monpotecourtier.fr',
    img: 'v1730206959/Mandataires%20Pretto%20Galaxie/MON_POTE_COURTIER_-_Yacine_Redissi.jpg',
    linkedin: 'https://www.linkedin.com/in/redissi/',
    instagram: 'https://www.instagram.com/monpotecourtier/',
    googlemybusiness: 'https://maps.app.goo.gl/szdPzB6dKRxjduhF6',
  },
  {
    lat: 50.635759,
    lng: 3.027602,
    name: 'Katia Bally',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'katia.bally@cfa-rachat-credit.fr',
    tel: '+33699510500',
    address: '169, avenue de Dunkerque, 59000 LILLE',
    img: 'v1730206958/Mandataires%20Pretto%20Galaxie/Katia_Bally.jpg',
    linkedin: 'www.linkedin.com/in/katia-bally-37691bb2',
    facebook: 'https://www.facebook.com/me/',
    googlemybusiness: 'https://g.co/kgs/c5SXpTv',
  },
  {
    lat: 48.703722,
    lng: 2.037052,
    name: 'Julian Modolo',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'julian.modolo@bypretto.fr',
    tel: '+33698850339',
    address: '63 Rue De Rambouillet, 78460 Chevreuse',
    img: 'v1729607989/Mandataires%20Pretto%20Galaxie/Julian_Modolo.jpg',
  },
  {
    lat: 48.865388,
    lng: 2.759309,
    name: 'Alper Kilic',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'alper.kilic@bypretto.fr',
    tel: '+33617037645',
    address: '15 Avenue De La Société Des Nations, 77144 Montévrain',
    website: 'www.creditimmobilier77.fr',
    img: 'v1729607987/Mandataires%20Pretto%20Galaxie/Alper_Kilic.jpg',
    linkedin: 'http://linkedin.com/in/alper-kilic-874171ba',
    facebook: 'https://www.facebook.com/creditimmobilier93',
    instagram: 'https://www.instagram.com/creditimmobilier77?igsh=NDkybjduNDIwODJ5&utm_source=qr',
    googlemybusiness: 'https://g.page/r/Cddyq1t3qh5bEBM',
  },
  {
    lat: 48.849817,
    lng: 2.27165,
    name: 'Liliana Veloso',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'liliana.veloso@bypretto.fr',
    tel: '+33698419454',
    address: '36 avenue Théophile Gautier, Paris 75016',
    website: 'https://finaway.fr/',
    img: 'v1729607981/Mandataires%20Pretto%20Galaxie/Liliana_Veloso.jpg',
    linkedin: 'https://www.linkedin.com/in/liliana-veloso-courtier-credit-immobilier/',
    facebook: 'https://www.facebook.com/finawaycourtage',
    instagram: 'https://www.instagram.com/finawaycourtage?igsh=MjlueXNzc3NpZHRz',
    googlemybusiness: 'https://g.co/kgs/BcGvJzX',
  },
  {
    lat: 49.187697,
    lng: -0.435799,
    name: 'Céline Colleville',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'celine.colleville@bypretto.fr',
    tel: '+33621596821',
    address: '4 Impasse Jean Hébert, 14650 Carpiquet',
    website: 'https://celinecolleville.fr/',
    img: 'v1729607981/Mandataires%20Pretto%20Galaxie/C%C3%A9line_Colleville.jpg',
    linkedin: 'https://www.linkedin.com/in/celine-colleville-375472121/',
    instagram: 'https://www.instagram.com/celinecolleville/',
    googlemybusiness: 'https://g.page/r/CeOU7mIgEzncEBM/review',
  },
  {
    lat: 44.838515,
    lng: -0.583076,
    name: 'Rubin Rilcy',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'rubin.rilcy@bypretto.fr',
    tel: '+33786079475',
    address: "61 Rue Du Château D'Eau, 33000 Bordeaux",
    website: 'https://www.davydeal.fr/',
    img: 'v1729265344/Mandataires%20Pretto%20Galaxie/Rubin_Rilcy.jpg',
    linkedin: 'linkedin.com/in/rubin-rilcy-963368216',
    facebook: 'https://www.facebook.com/profile.php?id=100015734267240',
    instagram: 'https://www.instagram.com/rubinrilcy/',
    googlemybusiness: 'https://maps.app.goo.gl/SWFTMdUDv51qrhiy8',
  },
  {
    lat: 47.320715,
    lng: 4.90615,
    name: 'Julie Le Bourdiec',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'julie.lebourdiec@bypretto.fr',
    tel: '+33671312609',
    address: '4 Clos Pingat, 21370 Velars Sur Ouche',
    website: 'https://courtier-immo-dijon.com/',
    img: 'v1729265215/Mandataires%20Pretto%20Galaxie/Julie_Le_Bourdiec.jpg',
    linkedin: 'https://www.linkedin.com/in/julie-le-bourdiec-61008410b/',
    googlemybusiness: 'https://g.co/kgs/B6DXqCW',
  },
  {
    lat: 46.179679,
    lng: 6.2591,
    name: 'Olivier Devenoges',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'o.devenoges@outlook.fr',
    tel: '+33781273666',
    address: '74100 Vetraz Monthoux',
    website: 'https://olivierdevenoges.fr/',
    img: 'v1729263200/Mandataires%20Pretto%20Galaxie/logo_Olivier_Devenoges.png',
    linkedin: 'www.linkedin.com/in/www.linkedin.com/in/olivierdevenoges',
    googlemybusiness: 'https://g.page/r/CQBfthojMLMJEBM/review',
  },
  {
    lat: 47.269147,
    lng: 4.990608,
    name: 'Patrick Gauchot',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'patrick.gauchot@bypretto.fr',
    tel: '+33641594572',
    address: '28 Rue Du Château, 21160 Marsannay-La-Côte',
    website: 'Courtier-credits.com',
    img: 'v1729262516/Mandataires%20Pretto%20Galaxie/Patrick_Gauchot.jpg',
    linkedin: 'https://www.linkedin.com/in/patrick-gauchot-14535315a/?originalSubdomain=fr',
    facebook: 'https://www.facebook.com/profile.php?id=100064171144060',
    googlemybusiness:
      'https://www.google.com/search?q=Patrick+gauchot&stick=H4sIAAAAAAAA_-NgU1I1qEg0N7FMMbBISUk2NEk1SUuxMqhIMzJLMTUHclIt0tLSUgwWsfIHJJYUZSZnK6QnliZn5JcAAO1p6gM7AAAA&hl=fr&mat=CbkSHkZIA3jzElYBEKoLaaRE9t_aUmpPZX4EpLNQ8bVTIrrkG2yEkX_3uJYuFC5J-RTZn6Ery9Ar3sYsKvK0GWKu-uirsfkwIh0fb_P5yDc-F0z_kHMbhLV-0TKQ5doe4Q&authuser=0',
  },
  {
    lat: 43.258136,
    lng: 5.39469,
    name: 'Erwan Melenec',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...); Courtier en prêt professionnel',
    email: 'emelenec@emc2-courtage.fr',
    tel: '+33624613144',
    address: '16 Avenue Maurice Barres, 13008 Marseille',
    website: 'https://www.emc2-courtage.fr/',
    img: 'v1734599873/Mandataires%20Pretto%20Galaxie/Erwan_Melenec.png',
    linkedin: 'https://www.linkedin.com/in/erwan-melenec-90066357/',
    facebook: 'https://www.facebook.com/Emc2courtage',
    googlemybusiness: 'https://g.co/kgs/CJNv2C1',
  },
  {
    lat: 48.884156,
    lng: 2.237199,
    name: 'Olivier Marti',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...); Courtier en prêt professionnel',
    email: 'olivier.marti@bypretto.fr',
    tel: '+33663155412',
    address: '92800 Puteaux',
    website: 'omarti.fr',
    img: 'v1729263894/Mandataires%20Pretto%20Galaxie/Olivier_Marti.jpg',
    linkedin: 'www.linkedin.com/in/olivier-marti-8838317',
    googlemybusiness: 'https://maps.app.goo.gl/i68TNtRqi55M2EwY6',
  },
  {
    lat: 48.912216,
    lng: 2.252555,
    name: 'Virginie Deshaires',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...); Courtier en prêt professionnel',
    email: 'v.deshaires@ficapconseils.fr',
    tel: '+33610965182',
    address: '11 Rue Médéric, 92250 La Garenne-Colombes',
    img: 'v1728985480/Mandataires%20Pretto%20Galaxie/Deshaires_Virginie.jpg',
    linkedin: 'https://www.linkedin.com/in/virginie-deshaires-b283a5121',
    googlemybusiness: 'https://g.co/kgs/jcQK8pi',
  },
  {
    lat: 43.604082,
    lng: 1.433805,
    name: 'Felana Andriantsalama',
    desc: 'Courtier en prêt immobilier; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'felana.andriantsalama@bypretto.fr',
    tel: '+33695456774',
    address: 'Toulouse',
    website: 'https://www.financement-et-assurance.fr/',
    img: 'v1731526927/Mandataires%20Pretto%20Galaxie/Felana_Andriantsalama.jpg',
    facebook: 'https://www.facebook.com/FelanaMoniaANDRIANTSALAMA/',
    googlemybusiness: 'https://g.page/r/CZfSnfVp8H6MEAE/review',
  },
  {
    lat: 46.30969,
    lng: 4.836502,
    name: 'Isabelle Jeunier',
    desc: 'Courtier en prêt immobilier; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'isabelle.jeunier@lescourtiersassocies.fr',
    tel: '+33603287448',
    address: '79 rue du 28 Juin, 71000 MACON',
    website: 'https://www.lescourtiersassocies.fr/pret-immo-construction-ou-sur-plan',
    img: 'v1730968825/Mandataires%20Pretto%20Galaxie/Isabelle_Jeunier.jpg',
    linkedin: 'https://www.linkedin.com/in/isabelle-jeunier-347334167/?originalSubdomain=fr',
    facebook: 'https://www.facebook.com/story.php/?story_fbid=1262802111563200&id=100035002705275&_rdr',
    googlemybusiness: 'https://g.co/kgs/JRZgFuh',
  },
  {
    lat: 48.112354,
    lng: -1.680067,
    name: 'Arnold Delemarre',
    desc: 'Courtier en prêt immobilier; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'arnold.delemarre@desancy.fr',
    tel: '+33662117223',
    address: '5 rue la Fayette, 35000 RENNES',
    website: 'www.desancy.fr',
    img: 'v1730968824/Mandataires%20Pretto%20Galaxie/Arnold_Delemarre.jpg',
    linkedin: 'linkedin.com/in/adelemarre-gestion-de-patrimoine',
    googlemybusiness: 'https://g.page/r/Cc_LQdQR39peEAE/review',
  },
  {
    lat: 48.877075,
    lng: 2.322753,
    name: 'Cyril Bouvet',
    desc: 'Courtier en prêt immobilier; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'contact@financia-patrimoine.fr',
    tel: '+33625474967',
    address: '1 Rue De Stockholm, 75008 Paris',
    website: 'Www.financia-patrimoine.fr',
    img: 'v1729607987/Mandataires%20Pretto%20Galaxie/logo_Cyril_Bouvet.png',
    facebook: 'https://www.facebook.com/profile.php?id=100083198623570',
    instagram: 'https://www.instagram.com/financia_patrimoine?igsh=ODVwem00cG03OTV0&utm_source=qr',
  },
  {
    lat: 47.815587,
    lng: 6.377344,
    name: 'Christophe Lejeune',
    desc: 'Courtier en prêt immobilier; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'c.lejeune@estencia.com',
    tel: '+33609172053',
    address: '32 Rue Jean Jaurès, 70300 Luxeuil-Les-Bains',
    website: 'www.christophe-lejeune.fr',
    img: 'v1729264057/Mandataires%20Pretto%20Galaxie/Christophe_Lejeune.jpg',
    linkedin: 'https://linkedin.com/in/christophe-lejeune-1a883483',
    facebook: 'https://facebook.com/1925062757731492',
    instagram: 'https://instagram.com/christophelejeune70',
    googlemybusiness: 'https://maps.google.com/maps?cid=5977429636381292755',
  },
  {
    lat: 47.414671,
    lng: -1.663118,
    name: 'Nicolas Chrétien',
    desc: 'Courtier en prêt immobilier; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'n.chretien@atlanticourtage.fr',
    tel: '+33650621475',
    address: '30 Avenue Gustave Eiffel, 44810 Héric',
    website: 'https://atlanticourtage.fr/',
    img: 'v1729263509/Mandataires%20Pretto%20Galaxie/Nicolas_Chr%C3%A9tien.png',
    linkedin: 'https://www.linkedin.com/in/nicolas-chretien-atlanticourtage/',
    facebook: 'https://www.facebook.com/profile.php?id=61551029833316',
    googlemybusiness: 'https://maps.app.goo.gl/xVWvXey2gPv3Qj5j6',
  },
  {
    lat: 45.561048,
    lng: 4.917694,
    name: 'Magali Routhe',
    desc: 'Courtier en prêt immobilier; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'magali.routhe@bypretto.fr',
    tel: '+33609163370',
    address: "302 Route De L'Église, 38200 Serpaize",
    website: 'https://only-courtage.com/',
    img: 'v1729262960/Mandataires%20Pretto%20Galaxie/Magali_Routhe.jpg',
    linkedin: 'https://www.linkedin.com/in/magalirouthe/',
    facebook: 'https://www.facebook.com/magali.routhe',
    instagram: 'https://www.instagram.com/jota.courtage/',
    googlemybusiness: 'https://g.co/kgs/sb3gUun',
  },
  {
    lat: 48.846016,
    lng: 2.379774,
    name: 'Hector Quidal',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...); Conseiller en Gestion de Patrimoine',
    email: 'hector.quidal@bypretto.fr',
    tel: '+33760510077',
    address: '117, rue de charenton, 75012 PARIS',
    website: 'www.toopret.fr',
    img: 'v1733742930/Mandataires%20Pretto%20Galaxie/Hector_QUIDAL_-_QUID_COURTAGE_ET_CONSEILS.jpg',
  },
  {
    lat: 48.965828,
    lng: 2.283348,
    name: 'Florent Chaouby',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...); Conseiller en Gestion de Patrimoine',
    email: 'florent.chaouby@bypretto.fr',
    tel: '+33651031495',
    address: '74 rue Berthie Albrecht, 95210 Saint-Gratien',
    website: 'https://www.cncef.org/annuaire/chaouby-florent/',
    img: 'v1731686268/Mandataires%20Pretto%20Galaxie/Florent_Chaouby.jpg',
    linkedin: 'https://www.linkedin.com/in/florentchaouby/',
  },
  {
    lat: 47.226594,
    lng: 5.894511,
    name: 'Laure Ribere',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...); Conseiller en Gestion de Patrimoine',
    email: 'laure.ribere@ribere-conseils.fr',
    tel: '+33620127751',
    address: 'place de la mare, 25320 chemaudin et vaux',
    website: 'ribere-conseils.fr',
    img: 'v1730968825/Mandataires%20Pretto%20Galaxie/Laure_Ribere.jpg',
  },
  {
    lat: 43.904631,
    lng: 1.906574,
    name: 'Emilie Taris',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...); Conseiller en Gestion de Patrimoine',
    email: 'emilie.taris.conseils@gmail.com',
    tel: '+33787451421',
    address: '13 impasse Elisée Reclus, 81600 GAILLAC',
    img: 'v1729607986/Mandataires%20Pretto%20Galaxie/Emilie_Taris.jpg',
  },
  {
    lat: 47.289607,
    lng: 5.76799,
    name: 'Séverin Gruet',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...); Conseiller en Gestion de Patrimoine',
    email: 'severin.gruet@bypretto.fr',
    tel: '+33688156139',
    address: '7 Avenue De Marnay La Ville, 70150 Marnay',
    img: 'v1729265343/Mandataires%20Pretto%20Galaxie/S%C3%A9verin_Gruet.jpg',
    linkedin: 'https://www.linkedin.com/in/s%C3%A9verin-gruet-courtage-et-patrimoine-606514192/',
    facebook: 'https://www.facebook.com/wagnerfinancements?locale=fr_FR',
    googlemybusiness: 'https://g.co/kgs/Mw2b2Ej',
  },
  {
    lat: 47.671435,
    lng: 7.265283,
    name: 'Anthony Vidal',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...); Conseiller en Gestion de Patrimoine',
    email: 'vidal.conseil@outlook.com',
    tel: '+33785308792',
    address: '4 Route De Mulhouse, 68720 Illfurth',
    website: 'https://vidal-conseil.fr/',
    img: 'v1729265256/Mandataires%20Pretto%20Galaxie/Anthony_Vidal.jpg',
    linkedin: 'linkedin.com/in/anthony-vidal-10378496',
    googlemybusiness: 'https://g.page/r/CZXSPDsbTvtDEBM',
  },
  {
    lat: 48.154205,
    lng: -1.834956,
    name: 'Sébastien Le Broch',
    desc: 'Conseiller en Investissements Financiers; Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'sebastienlebroch@soluria.fr',
    tel: '+33786777192',
    address: '34 RUE PIERRE DE COUBERTIN, 35590 SAINT-GILLES',
    website: 'https://www.soluria.fr/',
    img: 'v1733137491/Mandataires%20Pretto%20Galaxie/S%C3%A9bastien_LE_BROCH.jpg',
    linkedin: 'www.linkedin.com/in/sébastien-le-broch-6bb93a1a5',
    googlemybusiness: 'https://g.co/kgs/GezCCrc',
  },
  {
    lat: 47.359724,
    lng: -1.596446,
    name: 'Céline Coulon',
    desc: 'Courtier en prêt immobilier; Courtier en assurance (assurance emprunteur, prévoyance, ...); Conseiller en Investissements Financiers',
    email: 'celinecouloninvest@gmail.com',
    tel: '+33684162999',
    address: '14 Rue Des Bouleaux, 44119 Grandchamp Des Fontaines',
    website: 'https://www.celinecoulonpatrimoine.com',
    img: 'v1728985482/Mandataires%20Pretto%20Galaxie/C%C3%A9line_Coulon.jpg',
    linkedin: 'https://www.linkedin.com/in/c%C3%A9line-coulon-courtage',
    facebook: 'https://www.facebook.com/profile.php?id=100089557461100',
    instagram: 'https://www.instagram.com/celinecouloncourtage',
    googlemybusiness: 'https://g.co/kgs/wi8nLvT',
  },
  {
    lat: 48.877203,
    lng: 2.186794,
    name: 'François-Xavier Vittori',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...); Courtier en Immobilier Neuf',
    email: 'pretvisionimmo@gmail.com',
    tel: '+33650494420',
    address: "13 Boulevard De L'Hôpital Stell, 92500 Rueil Malmaison",
    img: 'v1729607993/Mandataires%20Pretto%20Galaxie/Fran%C3%A7ois-Xavier_VITTORI.jpg',
    linkedin: 'https://www.linkedin.com/in/fran%C3%A7ois-xavier-vittori-21774461/',
  },
  {
    lat: 48.41509,
    lng: 3.238248,
    name: 'Sabrina Solohub',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...); Assurances PNO et locataires',
    email: 'votrepartenaireconseil@gmail.com',
    tel: '+33685965007',
    address: "1 Rue De L'Église, 77480 Bray Sur Seine",
    website: 'https://calendly.com/votrepartenaireconseil/rdv-courtier-en-pret-immobilier-et-assurances?month=2024-10',
    img: 'v1729264021/Mandataires%20Pretto%20Galaxie/Sabrina_Solohub.jpg',
    linkedin: 'https://www.linkedin.com/in/sabrina-solohub-278419254/',
    facebook: 'https://www.facebook.com/sab.sol.165',
    instagram: 'https://www.instagram.com/vpc.courtier.immo/',
    googlemybusiness: 'https://g.co/kgs/g73y5dp',
  },
  {
    lat: 48.836976,
    lng: 2.242716,
    name: 'Sophie Hampard',
    desc: 'Courtier en prêt immobilier; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...); Courtier en rachat de crédits',
    email: 'sophie.hampard@bypretto.fr',
    tel: '+33652411079',
    address: '92100 Boulogne-Billancourt',
    img: 'v1729607994/Mandataires%20Pretto%20Galaxie/Portrait_Sophie_Hampard_-_Sophie_Hampard.jpg',
    linkedin: 'linkedin.com/in/sophie-hampard',
    facebook: 'https://www.facebook.com/SHFinancementsSophieHAMPARD/',
  },
  {
    lat: 43.703378,
    lng: 7.224818,
    name: 'Vincent Fauqueur',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...); Courtier en prêt hypothécaire',
    email: 'vincent.fauqueur@creditmix.fr',
    tel: '+33779694807',
    address: '209 Route De Bellet, Bat B3, 06200 Nice',
    website: 'http://www.creditmix.fr/',
    img: 'v1729263291/Mandataires%20Pretto%20Galaxie/logo_Vincent_Fauqueur.png',
    linkedin: 'https://www.linkedin.com/in/vincent-fauqueur-3b300768/',
    googlemybusiness: 'https://g.co/kgs/qyJH5dM',
  },
  {
    lat: 48.837611,
    lng: 2.258924,
    name: 'Mehdi Ouakouz',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...); Courtier en prêt professionnel',
    email: 'mehdi.ouakouz@bypretto.fr',
    tel: '+33699505867',
    address: '53 rue le Marois, 75016 Paris',
    website: 'https://courtierpretimmobilier.finance.blog/',
    img: 'v1734599164/Mandataires%20Pretto%20Galaxie/Mehdi_Ouakouz.jpg',
    linkedin:
      'https://www.linkedin.com/in/mehdi-ouakouz-9414b31ba?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    googlemybusiness: 'https://g.co/kgs/653fVZi',
  },
  {
    lat: 43.65729,
    lng: 1.4788,
    name: 'Romain Thomas',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'romain.thomas@bypretto.fr',
    tel: '+33637580367',
    address: "17 Avenue Des Hortensias, 31240 L'union",
    img: 'v1731579248/Mandataires%20Pretto%20Galaxie/Romain_thomas.png',
    linkedin: 'https://www.linkedin.com/in/romain-thomas-94a6ab8b/',
    googlemybusiness:
      'https://www.google.com/search?q=l%27agence+by+oleen&rlz=1C1ONGR_frFR1083FR1083&oq=l%27agence+by&gs_lcrp=EgZjaHJvbWUqEQgAEEUYJxg7GOMCGIAEGIoFMhEIABBFGCcYOxjjAhiABBiKBTIVCAEQLhgnGK8BGMcBGIAEGIoFGI4FMgYIAhBFGEAyBggDEEUYOTIHCAQQABiABDINCAUQLhivARjHARiABDINCAYQLhivARjHARiABDIGCAcQRRg90gEIMjI5NGowajeoAgiwAgE&sourceid=chrome&ie=UTF-8&zx=1731430511359&no_sw_cr=1',
  },
  {
    lat: 45.798617,
    lng: 4.825051,
    name: 'Pierre Lacondemine',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'pierre.lacondemine@bypretto.fr',
    tel: '+33672962166',
    address: '4 Rue Gabriel Chevallier, 69009 LYON',
    website: 'https://www.rocapatrimoine.fr/',
    img: 'v1731579074/Mandataires%20Pretto%20Galaxie/Pierre_Lacondemine.jpg',
    linkedin: 'www.linkedin.com/in/pierre-lacondemine',
    googlemybusiness: 'https://g.page/r/CRKhZXiBaevQEBM',
  },
  {
    lat: 47.239367,
    lng: -1.555335,
    name: 'Ronan Abillard',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'ronan.abillard@bypretto.fr',
    tel: '+33675478700',
    address: 'Nantes',
    img: 'v1731526934/Mandataires%20Pretto%20Galaxie/Ronan_Abillard.jpg',
    linkedin: 'https://www.linkedin.com/in/ronan-abillard-b8a91988/',
    facebook: 'https://www.facebook.com/ronanabillard.courtierenfinancementsimmobiliers',
  },
  {
    lat: 47.552778,
    lng: -2.481697,
    name: 'Gladys Jouin',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'gladys.jouin@bypretto.fr',
    tel: '+33784352383',
    address: '6 rue du général de gaulle, 56190 MUZILLAC',
    img: 'v1731526929/Mandataires%20Pretto%20Galaxie/Gladys_Jouin.jpg',
    facebook: 'https://www.facebook.com/share/1AbHc9oqkS/',
    googlemybusiness: 'https://g.co/kgs/mcib2Cu',
  },
  {
    lat: 43.549088,
    lng: 1.502528,
    name: 'Doorinsider Finance',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'doorinsider@bypretto.fr',
    tel: '+33143066364',
    address: "1000 rue l'Occitane, 31670 Labège",
    website: 'https://www.doorinsider.com/fr/pret-immobilier',
    img: 'v1731526926/Mandataires%20Pretto%20Galaxie/DoorInsider_Finance.png',
    linkedin: 'https://www.linkedin.com/company/doorinsider',
    facebook: 'https://www.facebook.com/doorinsider',
    instagram: 'https://www.instagram.com/doorinsider_com',
  },
  {
    lat: 48.703233,
    lng: 2.112886,
    name: 'Elisabeth Grimard',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'elisabeth.grimard@bypretto.fr',
    tel: '+33606403536',
    address: '6 impasse de la Maison Blanche, 91190 Gif-sur-Yvette',
    img: 'v1730968824/Mandataires%20Pretto%20Galaxie/Elisabeth_Grimard.png',
    linkedin: 'https://www.linkedin.com/in/%C3%A9lisabeth-grimard/',
  },
  {
    lat: 43.422652,
    lng: 3.235458,
    name: 'Fabrice Ibanez',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'fabrice.ibanez@bypretto.fr',
    tel: '+33659463789',
    address: '18 ruedu cabernet, 34290 Lieuran les béziers',
    img: 'v1730206960/Mandataires%20Pretto%20Galaxie/Fabrice_Ibanez.jpg',
  },
  {
    lat: 48.809707,
    lng: 2.483129,
    name: 'Odette De Jesus',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'odette.de-jesus@bypretto.fr',
    tel: '+33652922879',
    address: '80 avenue de Condé, 94100 SAINT MAUR DES FOSSES',
    img: 'v1729607992/Mandataires%20Pretto%20Galaxie/Odette_De_Jesus.jpg',
  },
  {
    lat: 48.611437,
    lng: 2.371561,
    name: 'Judicaël Ozier',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'judicael.ozier@bypretto.fr',
    tel: '+33613025533',
    address: '1 Impasse Des Noisetiers, 91070 Bondoufle',
    website: 'https://www.ajocourtage.com/',
    img: 'v1729607983/Mandataires%20Pretto%20Galaxie/Judicael_Ozier.jpg',
    linkedin: 'https://www.linkedin.com/in/judicael-ozier-03a261132/',
    facebook: 'https://www.facebook.com/ajocourtage',
    instagram: 'https://www.instagram.com/ajocourtage/',
    googlemybusiness: 'https://g.co/kgs/9D7sLqv',
  },
  {
    lat: 43.560681,
    lng: 7.005173,
    name: 'Sandra Paci',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'contact@finsur.fr',
    tel: '+33767914748',
    address: '06400 Cannes',
    website: 'www.finsur.fr',
    img: 'v1729265295/Mandataires%20Pretto%20Galaxie/Sandra_Paci.jpg',
    linkedin:
      'https://www.linkedin.com/in/sandra-paci-7a835b310/?trk=public_post_main-feed-card_feed-actor-name&originalSubdomain=fr',
    facebook: 'https://www.facebook.com/profile.php?id=61566604547606&locale=fr_FR',
    instagram: 'https://www.instagram.com/fin_sur_/',
  },
  {
    lat: 47.119558,
    lng: -2.102234,
    name: 'Paul Choisy',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'paul@choisy-ton-pret.fr',
    tel: '+33663799057',
    address: '36 Rue Rousse, 44210 Pornic',
    website: 'https://www.choisy-ton-pret.fr/',
    img: 'v1729263441/Mandataires%20Pretto%20Galaxie/Paul_Choisy.jpg',
    linkedin: 'https://www.linkedin.com/in/paulchoisy/',
    instagram: 'https://www.instagram.com/choisy_ton_pret/',
  },
  {
    lat: 44.136982,
    lng: 4.801743,
    name: 'Sandra Luppo',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'sandra-luppo@hotmail.fr',
    tel: '+33631663572',
    address: '234 Avenue Charles De Gaulles, 84100 Orange',
    website: 'www.sandra-luppo.com',
    img: 'v1729263326/Mandataires%20Pretto%20Galaxie/Sandra_Luppo.jpg',
    linkedin: 'https://www.linkedin.com/in/sandra-luppo-courtier/',
    facebook: 'https://www.facebook.com/sandraluppocourtier',
    instagram: 'https://www.instagram.com/sandra.luppo.courtier',
    googlemybusiness: 'https://g.co/kgs/77874nZ',
  },
  {
    lat: 48.089559,
    lng: 6.599947,
    name: 'Laurent Frechin',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'laurent.frechin@bypretto.fr',
    tel: '+33659710529',
    address: '16 Rue Du Canal, 88510 Eloyes',
    img: 'v1729263080/Mandataires%20Pretto%20Galaxie/Laurent_Frechin.jpg',
    linkedin:
      'https://www.linkedin.com/in/laurent-frechin-28251a118?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B9LFJTMiwQL6DBYmXSrJfoQ%3D%3D',
    facebook: 'https://www.facebook.com/laurent.frechinpro',
    googlemybusiness: 'https://g.co/kgs/F74XETW',
  },
  {
    lat: 46.097017,
    lng: -1.056558,
    name: 'Alexandre Smak',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'alexandre.smak@bypretto.fr',
    tel: '+33787744051',
    address: '5 Allée De Bourlande, 17220 Salles Sur Mer',
    img: 'v1729262837/Mandataires%20Pretto%20Galaxie/Alexandre_smak.jpg',
    linkedin: 'https://www.linkedin.com/in/alexandre-smak-5553401a3/',
  },
  {
    lat: 47.992079,
    lng: -4.087523,
    name: 'Sébastien Cosquer',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 's.cosquer@accord-de-pret.fr',
    tel: '+33610411038',
    address: '26 Rue Pic De La Mirandole, 29000 Quimper',
    website: 'https://www.accord-de-pret.fr/',
    img: 'v1729262762/Mandataires%20Pretto%20Galaxie/logo_Sebastien_Cosquer_ADP%20Financement.png',
    linkedin: 'https://www.linkedin.com/company/adp-financements-courtier/',
    facebook: 'https://www.facebook.com/adpfinancements',
    instagram: 'https://www.instagram.com/adpfinancements/',
    googlemybusiness: 'https://g.co/kgs/nP757vt',
  },
  {
    lat: 48.844814,
    lng: 2.373069,
    name: 'Manuel Bardoz',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...); Conseiller en Investissements Financiers',
    email: 'manuel.bardoz@mbcourtage-patrimoine.com',
    tel: '+33613906334',
    address: '4 Place Louis Armand, 75012 Paris',
    website: 'https://www.mbcourtagepatrimoine.com/',
    img: 'v1729263111/Mandataires%20Pretto%20Galaxie/Manuel_Bardoz.jpg',
    linkedin: 'https://www.linkedin.com/in/manuel-b-90235a89/',
    facebook: 'https://www.facebook.com/mbcourtageetpatrimoine',
    instagram: 'https://www.instagram.com/mb_courtage_et_patrimoine/',
    googlemybusiness:
      'https://www.google.com/search?sca_esv=0e4c1125ea98658e&uds=ADvngMjcH0KdF7qGWtwTBrP0nt7dfyVq210j0KEYJljp8j1y0AXnpFHkfAEq6kn7-JN-y8zhLMBJcedzXqMMM_MRtaq5a-6Zns7KYlhlm7TCX32BMbRBHaY20qGcpdsDfUkvekWPQQWFu4sYEcznTumbiFmQeMA2jw&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7tJUbUeC7mkP1Ief37Jrgq-pWoUJc3GhqEM-sOTwsRi62D7vfjYCQf21cQ3Gb4vRsdw_THA%3D&q=MB+Courtage+%26+Patrimoine+Courtier+en+pr%C3%AAt+et+Conseiller+en+investissement+financier+Avis&sa=X&ved=2ahUKEwiayPOtqJWJAxXOTKQEHQYuI8IQ3PALegQIWBAF&biw=2240&bih=1172&dpr=2',
  },
  {
    lat: 49.304666,
    lng: 1.009803,
    name: 'Maud Vincent',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...); Leasing Immobilier',
    email: 'devincifinance@bypretto.fr',
    tel: '+33615458436',
    address: '12 Rue De Verdun, 76410 Saint Aubin Les Elbeuf',
    website: 'https://devincifinance.fr',
    img: 'v1729263475/Mandataires%20Pretto%20Galaxie/Maud_Vincent.jpg',
    linkedin: 'https://www.linkedin.com/in/maud-de-vinci-329b30173/',
    facebook: 'https://www.facebook.com/profile.php?id=61551567284947',
    instagram: 'https://www.instagram.com/devincifinance/',
    googlemybusiness:
      'https://www.google.com/maps/place/De+Vinci+Finance+courtier+en+cr%C3%A9dit+immobilier,+Assurance+emprunteur,+Rachat+de+cr%C3%A9dit,+cr%C3%A9dit+professionnel./@49.2895494,1.0073797,13z/data=!4m6!3m5!1s0x8ae092c64c997855:0xb459c22eae11b2ed!8m2!3d49.2895494!4d1.0073797!16s%2Fg%2F11y2cxbhs3?entry=ttu&g_ep=EgoyMDI0MTAxNC4wIKXMDSoASAFQAw%3D%3D',
  },
  {
    lat: 49.032165,
    lng: 2.336739,
    name: 'Christian Doman',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en Immobilier Neuf; Courtier en assurance (assurance emprunteur, prévoyance, ...); Courtier en prêt professionnel',
    email: 'contact@i-finance.fr',
    tel: '+33789219764',
    address: '6 rue de la gare 95330 DOMONT',
    website: 'https://www.i-finance.fr/',
    img: 'v1734599162/Mandataires%20Pretto%20Galaxie/Christian_Doman.jpg',
    instagram: 'https://www.instagram.com/immofinance__domont/profilecard/?igsh=d3YyanV2Njk1MHgw',
    googlemybusiness: 'https://g.co/kgs/xrnXx8A',
  },
  {
    lat: 48.863016,
    lng: 2.376384,
    name: 'Karine Brunet & Yahia Sadouni',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...); Courtier en prêt hypothécaire',
    email: 'equipe@gshcourtage.com',
    tel: '+33777939064',
    address: '64 Rue Parmentier, 75011 Paris',
    website: 'www.gshcourtage.com',
    img: 'v1731526929/Mandataires%20Pretto%20Galaxie/GSH_Courtage.png',
    facebook: 'https://www.facebook.com/profile.php?id=61568776765763&sk=about',
  },
  {
    lat: 48.809707,
    lng: 2.483129,
    name: 'Stéphanie Dietrich',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en prêt hypothécaire; Courtier en assurance (assurance emprunteur, prévoyance, ...)',
    email: 'stephanie.dietrich@bypretto.fr',
    tel: '+33662337741',
    address: '80 rue de Condé, 94100 Saint Maur des Fossés',
    img: 'v1729607990/Mandataires%20Pretto%20Galaxie/St%C3%A9phanie_Dietrich.jpg',
    linkedin: 'http://www.linkedin.com/in/stéphanie-dietrich-000846173',
    facebook: 'https://www.facebook.com/stephanie.dietrich.148/',
    instagram: 'https://www.instagram.com/dietrich.m.stephanie/',
    googlemybusiness: 'https://maps.app.goo.gl/TjtiRvjrngCfaWfeA',
  },
  {
    lat: 48.91587,
    lng: 1.872477,
    name: 'Grégory Perrot',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...); Conseiller en Gestion de Patrimoine',
    email: 'gperrot@perrotimmoconseil.com',
    tel: '+33616379546',
    address: '8 Avenue Pasteur, 78580 Maule',
    website: 'https://www.perrotimmoconseil.com',
    img: 'v1729263851/Mandataires%20Pretto%20Galaxie/Gr%C3%A9gory_Perrot.jpg',
    linkedin: 'https://www.linkedin.com/in/gregory-perrot-1362b0',
    googlemybusiness: 'https://g.co/kgs/XbQqh7M',
  },
  {
    lat: 48.875376,
    lng: 2.285133,
    name: 'Samuel Tordjeman',
    desc: 'Conseiller en Investissements Financiers; Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en assurance (assurance emprunteur, prévoyance, ...); Courtier en prêt professionnel',
    email: 'samuel.tordjeman@bypretto.fr',
    tel: '+33787334994',
    address: '17 Rue Pergolese, 75116 Paris',
    website: 'https://www.agile-conseil-patrimonial.com/',
    img: 'v1731526935/Mandataires%20Pretto%20Galaxie/Samuel_Tordjeman.jpg',
    linkedin: 'https://www.linkedin.com/in/samuelconseilinvestissement/',
    googlemybusiness: 'https://g.page/r/Cecd87dnc_76EBM/review',
  },
  {
    lat: 46.499418,
    lng: -0.415506,
    name: 'Damien Merceron',
    desc: 'Courtier en prêt immobilier; Courtier en rachat de crédits; Courtier en prêt professionnel; Courtier en assurance (assurance emprunteur, prévoyance, ...); Conseiller en Investissements Financiers',
    email: 'contact@dmconseils.com',
    tel: '+33628613880',
    address: '3 Chemin Du Pinier, 79220 Cours',
    website: 'https://www.dmconseils.com/',
    img: 'v1729607993/Mandataires%20Pretto%20Galaxie/Damien_Merceron.jpg',
    linkedin: 'https://www.linkedin.com/in/merceron-damien/',
    facebook: 'https://www.facebook.com/profile.php?id=100057669000719',
    googlemybusiness: 'https://g.page/r/CcnfGc0ANOUvEBM/review',
  },
]
